console.log(process.env);
export const configuration = {
  apiUrl: process.env.REACT_APP_API_URL,
  landingUrl: "/menu",
  projectName: "gftdemo",
  config: {
    snackbar: {
      autoHideDuration: 3000,
      anchorOrigin: {horizontal: "center", vertical: "bottom"}
    },
    numericFormat: {
      delimiter: ",",
      decimal: ".",
      currency: "$"
    },
    fillCharacter: {
      errorCharacter: "*",
      lowValuesCharacter: "\0",
      underscoreCharacter: "_"
    },
    functionKey: {
      errorMessageType: "error",
      enableKeyScroll: false,
      scrollAmount: 100
    },
    validation: {
      enableOnKey: true,
      enableOnTouch: true
    }
  },
};
