import { Navigate, useLocation } from "react-router-dom";
import { configuration } from "./environment/configuration";
import Layout from "./components/layout/Layout";
import Menu from "./components/menu/Menu";
import Console from "./components/common/Console";
import React from "react";
import { lazy } from 'react';

function WrapPanel() {

    const location = useLocation();
    const rawPathname = "" + location.pathname;
    const lcLocation = rawPathname.toLowerCase();
    const lcIndexLastSlash = lcLocation.lastIndexOf("/");
    const lcMapName = lcIndexLastSlash !== -1 ? lcLocation.substring(lcIndexLastSlash+1) : lcLocation;
    const ccMapName = lcMapName.substring(0, 1).toUpperCase() + lcMapName.substring(1);
    const projectName = configuration.projectName !== "" ? configuration.projectName : "";

    try {
        const ThisComponent = (rawPathname !== "")
                ? lazy(() => import('./components/' + projectName + lcLocation + "/" + ccMapName))
                : null;

        return (
            <React.Suspense fallback={<>...</>}>
               {ThisComponent ? <ThisComponent /> : null}
            </React.Suspense>
        );
    } catch (err) {
        return <span className="bms__content bms__text--red" >{err}</span>;
    }

}

export const getRoutes = () => {
    return [
        {
            path: "/",
            element: <Navigate replace to={configuration.landingUrl} />,
        },
        {
            path: "/",
            element: <Layout />,
            children: [
                { //Handle top-level menu.
                    path: "menu",
                    element:
                        <Menu />,
                },
                { //Handle Console map.
                    path: "console/console",
                    element: <Console />,
                    errorElement: <Navigate replace to={configuration.landingUrl} />
                },
                { //Handle all other app maps.
                    path: "*",
                    element: <WrapPanel />,
                    errorElement: <Navigate replace to={configuration.landingUrl} />
                }
            ]
        }
    ];
}
