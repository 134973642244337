import React, {useContext, useEffect} from "react";
import {
  useLocation,
  useNavigate
} from "react-router";
import {useNavigationBlocker} from "@tsri/react";
import {ConfigurationContext} from "@tsri/react";
import {ConnectionContext} from "@tsri/react";
import {useSessionManager} from "@tsri/react";

/***
 * This react HOC enables each component with dialog and navigation handling.
 * @param Component - screen component
 *
 * @return Component - new component with dialog and navigation handling.
 */
const withApp = (Component) => props => {
  const location = useLocation();
  const navigate = useNavigate();

  const configuration = useContext(ConfigurationContext);
  if (!configuration) {
    throw Error("withApp HoC must be used with a configuration context provider.");
  }

  const context = useContext(ConnectionContext);

  // Use Navigation Blocker.
  // This blocks users from navigating from the current page and displays a dialog.
  // @ts-ignore
  const [exit] = useNavigationBlocker({config: {landingUrl: configuration.landingUrl}});

  // Use session manager for connecting and disconnecting the user.
  const {connect, disconnect} = useSessionManager({config: configuration});

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);

    if (exit) {
      disconnect(); // Disconnects users on exit.
    }
  }, [exit, disconnect]);

  // When screen is refreshed, get state object from localStorage using path as the key
  const getStateFromStorage = (localStorage: Storage, location: { pathname: any; state: any; }) => {
    let path = location.pathname;
    let state = JSON.parse(localStorage.getItem(path));
    location.state = state;
    return state;
  }

  return (
      <Component
          {... props} connect={connect} context={context}
          {...{ navigate, location }} data={location.state? location.state : getStateFromStorage(localStorage, location)} />
  );
}

export default withApp;