import {useLocation, useNavigate} from "react-router";
import {RouterContext} from "@tsri/react";
import React from "react";

/***
 * This react HOC enables the framework with Router location and navigation.
 * Use this on a component within a router.
 */
const withRouteProvider = (Component) => props => {
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <RouterContext.Provider value={{location, navigate}}>
            <Component {... props} />
        </RouterContext.Provider>);
}

export default withRouteProvider;