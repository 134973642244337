import React, {useState} from "react";
import {configuration} from "../../environment/configuration";
import {ConfigurationContext} from "@tsri/react";
import {ConfirmationDialogProvider} from "@tsri/react";
import {MessageDialogProvider} from "@tsri/react";
import {ConnectionContext} from "@tsri/react";
import {SnackbarProvider} from "@tsri/react";
import {FieldProvider} from "@tsri/react";


/***
 * This react HOC enables various providers.
 * Use this at the root component to provide globals providers such as dialogs, connection, and configurations.
 * @param Component - root component
 *
 * @return Component - root component with attached providers for various functions.
 */
const withProviders = (Component) => props => {
    const [connection] = useState({client: {apiUrl: configuration.apiUrl}, id: ""});
    return (
        <ConfigurationContext.Provider value={configuration}>
            <ConnectionContext.Provider value={connection}>
                <ConfirmationDialogProvider>
                    <MessageDialogProvider>
                        <SnackbarProvider>
                            <FieldProvider>
                                <Component
                                    {... props} />
                            </FieldProvider>
                        </SnackbarProvider>
                    </MessageDialogProvider>
                </ConfirmationDialogProvider>
            </ConnectionContext.Provider>
        </ConfigurationContext.Provider>
    );
}

export default withProviders;