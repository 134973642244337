import React, {Component} from 'react';
import {menu} from "../../environment/menu";
import './Menu.scss';
import {compose} from "@tsri/react";
import withApp from "../hooks/WithApp";

class Menu extends Component<any> {
  constructor(props) {
    super(props);
    this.handleStart = this.handleStart.bind(this);
  }

  handleStart(programId) {
      this.props.connect?.(programId);
  }

  render() {
    const programListItems = menu.map((item) =>
      <div key={item.name} className="program__card">
        <div className="program__header">
          <div className="header__image">
            <img src={item.image} alt="" />
          </div>
          <h1>{item.name}</h1>
          <h2>{item.description}</h2>
          <h2>{item.target}</h2>
        </div>
        <div className="program__button" onClick={() => this.handleStart(item.programId)} >
          <p className="program__button--text">Start Program</p>
        </div>
        <ul className="logo">
          <li>
            <div className="logo__name">
              TSRI
            </div>
          </li>
        </ul>
      </div>
    );
    return (
      <div className="menu">
        <div className="program">
          <div className="program__list">
            {programListItems}
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
    React.memo,
    withApp,
    // Replace with connection hook.
    // withSocket
)(Menu);