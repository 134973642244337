import React, {Component} from 'react';
import './scss/styles.scss';
import './scss/nav.scss';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {getRoutes} from "./Routes";
import withProviders from "./components/hooks/WithProviders";
import {compose} from "@tsri/react";

class App extends Component<{}, any> {
    render() {
        const router = createBrowserRouter(getRoutes());

        return (
            <div className="root">
                <RouterProvider router={router}/>
            </div>
        );
    }
}

export default compose(React.memo,withProviders)(App);
