import React, {useState} from "react";
import {LoadingContext} from "@tsri/react";

/***
 * This react HOC enables the framework with Loading Progress.
 */
const withLoading = (Component) => props => {
  const [isLoading, setIsLoading] = useState(false);

  const setLoading = (loading) => {
    setIsLoading(loading);
  }

  return (
    <LoadingContext.Provider value={{loading: isLoading, setLoading: setLoading}}>
      <Component {... props} isLoading={isLoading} />
    </LoadingContext.Provider>
  );
}

export default withLoading;