import React, {Component} from 'react';
import {Outlet} from "react-router-dom";
import "./Layout.scss";
import withRouteProvider from "../hooks/WithRouteProvider";
import {compose} from "@tsri/react";
import withLoading from "../hooks/WithLoading";
import {LinearProgress} from "@mui/material";

class Layout extends Component<any> {
  render() {
    return (
      <div className="layout">
        <nav className="nav">
          <ul className="nav__container">
          </ul>
        </nav>
        <div className="loading">
          {this.props.isLoading? <LinearProgress /> : null}
        </div>
        <main id="main" className="main" role="main">
          <div className="bms__screen">
            <Outlet/>
          </div>
        </main>
      </div>
    );
  }
}

export default compose(React.memo, withRouteProvider, withLoading)(Layout);