import * as React from "react";
import {Component} from "react";
import withApp  from "../hooks/WithApp";
import  "../../scss/bms.scss";
import {BmsFormField} from "@tsri/react";
import {BmsScreen} from "@tsri/react";

class Console  extends Component<any, any>
{
    constructor(props)
    {
        super(props);
        this.state = {data : props.data, id : props.context.id};
        this.handleStateChange = this.handleStateChange.bind(this);
        this.setFieldData = this.setFieldData.bind(this);
    }
    componentDidMount()
    {
        this.handleStateChange(this.props.location.state);
    }
    handleStateChange(result)
    {
        this.setState(() =>
        {
            let data  = result;
            return {data};
        });
    }
    setFieldData(field)
    {
        this.setState(prevState =>
        {
            let data  = Object.assign({}, prevState.data) as any;
            data.map.fields[field.NAME] = field;
            return {data};
        });
    }
    render()
    {
        let map  = this.state.data.map;

        if (!map)
        {
            return (<div className="bms__content bms__text--neutral">CONSOLE MAP NOT FOUND.</div>);
        }
        return (<BmsScreen id="CONSOLE" size={{rows : 24, columns : 80}} data={this.state}>
            <BmsFormField id="LINE1" type="OUTPUT" position={{row : "1", column : "2"}} length="78" data={map.fields["LINE1"]} setData={this.setFieldData}/>
            <BmsFormField id="LINE2" type="OUTPUT" position={{row : "2", column : "2"}} length="78" data={map.fields["LINE2"]} setData={this.setFieldData}/>
            <BmsFormField id="LINE3" type="OUTPUT" position={{row : "3", column : "2"}} length="78" data={map.fields["LINE3"]} setData={this.setFieldData}/>
            <BmsFormField id="LINE4" type="OUTPUT" position={{row : "4", column : "2"}} length="78" data={map.fields["LINE4"]} setData={this.setFieldData}/>
            <BmsFormField id="LINE5" type="OUTPUT" position={{row : "5", column : "2"}} length="78" data={map.fields["LINE5"]} setData={this.setFieldData}/>
            <BmsFormField id="LINE6" type="OUTPUT" position={{row : "6", column : "2"}} length="78" data={map.fields["LINE6"]} setData={this.setFieldData}/>
            <BmsFormField id="LINE7" type="OUTPUT" position={{row : "7", column : "2"}} length="78" data={map.fields["LINE7"]} setData={this.setFieldData}/>
            <BmsFormField id="LINE8" type="OUTPUT" position={{row : "8", column : "2"}} length="78" data={map.fields["LINE8"]} setData={this.setFieldData}/>
            <BmsFormField id="LINE9" type="OUTPUT" position={{row : "9", column : "2"}} length="78" data={map.fields["LINE9"]} setData={this.setFieldData}/>
            <BmsFormField id="LINE10" type="OUTPUT" position={{row : "10", column : "2"}} length="78" data={map.fields["LINE10"]} setData={this.setFieldData}/>
            <BmsFormField id="LINE11" type="OUTPUT" position={{row : "11", column : "2"}} length="78" data={map.fields["LINE11"]} setData={this.setFieldData}/>
            <BmsFormField id="LINE12" type="OUTPUT" position={{row : "12", column : "2"}} length="78" data={map.fields["LINE12"]} setData={this.setFieldData}/>
            <BmsFormField id="LINE13" type="OUTPUT" position={{row : "13", column : "2"}} length="78" data={map.fields["LINE13"]} setData={this.setFieldData}/>
            <BmsFormField id="LINE14" type="OUTPUT" position={{row : "14", column : "2"}} length="78" data={map.fields["LINE14"]} setData={this.setFieldData}/>
            <BmsFormField id="LINE15" type="OUTPUT" position={{row : "15", column : "2"}} length="78" data={map.fields["LINE15"]} setData={this.setFieldData}/>
            <BmsFormField id="LINE16" type="OUTPUT" position={{row : "16", column : "2"}} length="78" data={map.fields["LINE16"]} setData={this.setFieldData}/>
            <BmsFormField id="LINE17" type="OUTPUT" position={{row : "17", column : "2"}} length="78" data={map.fields["LINE17"]} setData={this.setFieldData}/>
            <BmsFormField id="LINE18" type="OUTPUT" position={{row : "18", column : "2"}} length="78" data={map.fields["LINE18"]} setData={this.setFieldData}/>
            <BmsFormField id="LINE19" type="OUTPUT" position={{row : "19", column : "2"}} length="78" data={map.fields["LINE19"]} setData={this.setFieldData}/>
            <BmsFormField id="LINE20" type="OUTPUT" position={{row : "20", column : "2"}} length="78" data={map.fields["LINE20"]} setData={this.setFieldData}/>
            <BmsFormField id="LINE21" type="OUTPUT" position={{row : "21", column : "2"}} length="78" data={map.fields["LINE21"]} setData={this.setFieldData}/>
            <BmsFormField id="LINE22" type="OUTPUT" position={{row : "22", column : "2"}} length="78" data={map.fields["LINE22"]} setData={this.setFieldData}/>
            <BmsFormField id="LINE23" type="OUTPUT" position={{row : "23", column : "2"}} length="78" data={map.fields["LINE23"]} setData={this.setFieldData}/>
            <BmsFormField id="LINE24" type="OUTPUT" position={{row : "24", column : "2"}} length="78" data={map.fields["LINE24"]} setData={this.setFieldData}/>
        </BmsScreen>);
    }
}
export default withApp(Console);
